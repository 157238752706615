import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import { Gradient, GradientWapper } from "../components/gradients";
import Footer from "../components/footer";
import Decor from "../components/decor";

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          summary
          date(formatString: "LL")
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;

const DecorSettings = {
  desktop: [
    {
      colour: "blue",
      size: 25,
      left: 210,
      top: -100,
    },
    {
      colour: "white",
      size: 15,
      left: -138,
      top: -120,
    },
    {
      colour: "white",
      size: 11,
      left: 160,
      top: 0,
    },
    {
      colour: "purple",
      size: 22,
      left: -270,
      top: -30,
    },
  ],
  mobile: [
    {
      colour: "blue",
      size: 14,
      left: 110,
      top: -70,
    },
    {
      colour: "white",
      size: 10,
      left: -109,
      top: -60,
    },
    {
      colour: "white",
      size: 7,
      left: 100,
      top: -20,
    },
    {
      colour: "purple",
      size: 13,
      left: -130,
      top: -30,
    },
  ],
};

const Page = styled.div`
`;

const Header = styled.div`
  width: 35rem;
  padding: 6rem 0 0;
  text-align: center;

  h1 {
    width: 26rem;
    margin: 0 auto;
  }

  p {
    margin: 1.5rem 0 0;
    font-size: 1.2rem;
  }

  @media screen and (max-width: ${BREAKPOINTS["tablet"]}px) {
    width: 100%;
    padding: 2rem 0 1rem;

    h1 {
      width: 80%;
      margin: 0 auto;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const ArticlesBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    display: flex;
    flex-direction: column;
    grid-gap: initial;
    width: 94%;
    margin: 0 auto;
  }
`;

const FeaturedArticle = styled.div`
 margin: 3rem 0;
 > a {

  display: flex;
  .thumbnail {
    max-width: 35%;
  }
  .title {
    color: #f1f2f4;
  }
  .content {
    padding: 0 2rem;
    > span,
    a {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .date, .summary {
    font-size: 0.9em;
  }
}

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    display: none;
  }
`;

const ArticlesBox = styled.div`
  > a {
    > span {
      font-size: 0.9em;
      display: block;

      &.date {
        padding-top: .8rem;
        padding-bottom: .5rem;
        font-size: 0.8em;
      }
    }
    .summary {
      padding-top: .5rem;
      font-size: 0.8em;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin-bottom: 2rem;
  }
`;

const BlogPage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });

  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  const featuredPost = data?.allMarkdownRemark?.nodes?.[0];

  return (
    <Page>
      <Layout
        title="Blog"
        description="Stay informed about new features, security improvements, and other updates from the Bifrost Wallet team."
      >
      <GradientWapper>
        <Gradient position="topLeft" color="blue" />
        <Block noBottomPadding={isMobile}>
        <Header>
        <Decor
          settings={
            isMobile ? DecorSettings.mobile : DecorSettings.desktop
          }
        >
          <h1>Blog</h1>
          <p>Stay informed about new features, security improvements, and other updates from the Bifrost Wallet team.</p>
          </Decor>
        </Header>
        </Block>
      </GradientWapper>

        { !isMobile &&  <Block noBottomPadding>
          <FeaturedArticle>
            <Link className="article-card" to={featuredPost.fields.slug}>
              <div className="thumbnail">
                <GatsbyImage
                  image={getImage(featuredPost.frontmatter.coverImage)}
                  alt={featuredPost.frontmatter.title}
                  quality={66}
                  placeholder="none"
                  imgStyle={{borderRadius: 25}}
                  loading="eager"
                />
              </div>
              <div className="content">
                <span className="date f-m-m">
                  {featuredPost.frontmatter.date}
                </span>
                <span className="title hover-effect f-3xl">
                  {featuredPost.frontmatter.title}
                </span>
                <p className="f-l-m summary">
                  {featuredPost.frontmatter.summary}
                </p>
              </div>
            </Link>
          </FeaturedArticle>
        </Block>}

        <Block>
          <GradientWapper>
            <Gradient position="topRight" color="blue" />
              <ArticlesBoxes>
                {data?.allMarkdownRemark?.nodes.map((article, key) => {
                  if (key > (isMobile ? -1 : 0)) {
                    return (
                      <ArticlesBox key={key}>
                        <Link className="article-card" to={article.fields.slug}>
                          <GatsbyImage
                            image={{...getImage(article.frontmatter.coverImage), width: 870, height: 600}}
                            alt={article.frontmatter.title}
                            quality={66}
                            placeholder="none"
                            objectFit="cover"
                            imgStyle={{borderRadius: 25}}
                            loading={key === 0 ? 'eager' : 'lazy'}
                          />

                          <span className="date">
                            {article.frontmatter.date}
                          </span>

                          <span className="title hover-effect">
                            {article.frontmatter.title}
                          </span>

                          <p className="summary f-l-m">
                            {article.frontmatter.summary}
                          </p>
                        </Link>
                      </ArticlesBox>
                    );
                  } else {
                    return null;
                  }
                })}
              </ArticlesBoxes>
          </GradientWapper>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export default BlogPage;
